import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";

export default function Navbar({ isNavDark }: any) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const redirectUrl = import.meta.env.VITE_REDIRECT;
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative navbar-b">
      <nav className=" relative flex items-center min-w-screen ">
        <ul className="flex py-4 w-full h-16 items-center justify-between md:hidden">
          <li className="w-10">
            <button onClick={toggleMenu} className="text-2xl">
              ☰
            </button>
          </li>
          <li className="flex-grow text-center pt-[10px]">
            <button onClick={() => navigate("/")} className="logo">
              <img src={logo} alt="Logo" className="h-8 mx-auto" />
            </button>
          </li>
          <li className="w-20 text-end">
            <button
              onClick={() => window.open("http://app.adaptacs.com", "_blank")}
              type="button"
              className="text-black bg-white border border-[#80D467] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-xs px-3 py-1 text-center"
            >
              Sign in
            </button>
          </li>
        </ul>
        <ul className="hidden md:flex py-5 w-full h-20 items-center justify-between">
          <li className="flex items-center">
            <button onClick={() => navigate("/")} className="logo">
              <img src={logo} alt="Logo" className="h-10" />
            </button>
          </li>
          <li className="flex space-x-4">
            {["About Us", "Contact Us", "Help"].map((item) => (
              <NavLink
                key={item}
                to={`/${item.toLowerCase().replace(" ", "-")}`}
                className={({ isActive }) =>
                  `px-4 py-2 rounded-lg text-sm font-semibold ${
                    isActive
                      ? "text-[#008D06]"
                      : isNavDark
                      ? "text-black"
                      : "text-white"
                  }`
                }
              >
                {item}
              </NavLink>
            ))}
          </li>
          <li>
            <button
              onClick={() => window.open(redirectUrl, "_blank")}
              type="button"
              className="text-black bg-white border border-[#80D467] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2 text-center"
            >
              Sign in
            </button>
          </li>
        </ul>
      </nav>
      <div
        className={`md:hidden absolute top-16 left-0 z-20 right-0 bg-white shadow-md transition-all duration-300 ${
          isMenuOpen ? "max-h-screen" : "max-h-0 overflow-hidden"
        }`}
      >
        <ul className="p-4 space-y-4">
          {["About Us", "Contact Us", "Help"].map((item) => (
            <li key={item}>
              <NavLink
                to={`/${item.toLowerCase().replace(" ", "-")}`}
                className={({ isActive }) =>
                  `block px-4 py-2 rounded-lg text-sm font-semibold ${
                    isActive ? "text-[#008D06]" : "text-black"
                  }`
                }
                onClick={toggleMenu}
              >
                {item}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
