//@ts-nocheck

import cropsImg from "../assets/icons/crop.svg";
import location from "../assets/icons/location.svg";
import Select from "react-select";

// import { useForm, SubmitHandler } from "react-hook-form";

// type Inputs = {
//   example: string;
//   exampleRequired: string;
// };
import time from "../assets/icons/time.svg";
import Places from "./PlacesAutocomplete";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

import { useMediaQuery } from "react-responsive";
import image1 from "../assets/images/image1.png";
import image2 from "../assets/images/image2.png";
import image3 from "../assets/images/image3.png";
import image4 from "../assets/images/image4.png";
import PartialReport from "./PartialReport";
import { Button, Label, Modal } from "flowbite-react";
import baseInstance from "../api";
import { formatDate } from "../utils";
import axios from "axios";
import ModalSelf from "./Modal";
import PartialReportLivestock from "./PartialReportLivestock";
import Turnstile from "./Turnstile";
import TurnstileWidget from "./Turnstile";
import FormCaptcha from "./FormCaptcha";

export const Hero = ({ openMapPopup, setOpenMapPopup }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [selected, setSelected] = useState(null);
  const [timePeriod, setTimePeriod] = useState(null);
  const [selectedCrop, setSelectedCrop] = useState("");
  const [fade, setFade] = useState(true);
  const [isProtected, setIsProtected] = useState(false);
  const [allCrops, setAllCrops] = useState([]);
  const [reportData, setReportData] = useState({});
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [profitData, setProfitData] = useState({});
  const [reportId, setReportId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [source, setSource] = useState("");
  const type = useRef(null);
  const selectedOption = useRef(null);
  const [months, setMonths] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const [isLivestock, setIsLivestock] = useState(false);
  const [option, setOption] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [riskId, setRiskId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstQuestion, setFirstQuestion] = useState([]);
  const [livestock, setLivestock] = useState([]);
  const [step, setStep] = useState(0);
  const [sumbmit, setSumbit] = useState({});
  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const images = [image1, image2, image3, image4];
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [isComputed, setIsComputed] = useState(false);
  const [livestockData, setIsLivestockData] = useState([]);
  const [inputValue, setinputValue] = useState(null);
  const [reportIdLivestock, setReportIdLivestock] = useState("");
  const [questionnaires, setQuestionnaires] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [debugInfo, setDebugInfo] = useState("");
  const [turnstileToken, setTurnstileToken] = useState("");
  const [firstAnswer, setFirstAnswer] = useState("");
  const [openCapPopup, setOpenCapPopup] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  // Ensure questionnaires are fully loaded and log them
  useEffect(() => {
    console.log("Total questions:", questionnaires.length);
    console.log("Current question index:", currentQuestionIndex);
    console.log("Current question:", questionnaires[currentQuestionIndex]);
    setDebugInfo(
      `Question ${currentQuestionIndex + 1} of ${questionnaires.length}`
    );
  }, [questionnaires, currentQuestionIndex]);

  const currentQuestion = questionnaires[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questionnaires.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  const handleAnswer = (value) => {
    // Use `category_type` instead of `category_name` to store the answer
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestion?.category_type]: value,
    }));
  };
  // Move to the next question or submit if it's the last one
  const handleNext = async () => {
    if (!currentQuestion) {
      console.error("Current question is undefined");
      return;
    }

    console.log("Saving answer for:", currentQuestion?.category_name);

    // Ensure we don't go out of bounds
    if (currentQuestionIndex < questionnaires.length - 1) {
      // Reset answer for the next question to avoid pre-filling
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      await handleSubmit(); // If last question, submit the answers
    }
  };

  // @ts-ignore
  const CROP_URL = "v1/public/eco-crop/eco-crop-type?page=1&per_page=5000";
  const COMPUTE_SCORE_CROP = "v1/public/eco-crop/compute-risk";
  console.log(inputValue, " input value ", type.current);
  async function fetchCrops() {
    const res = await baseInstance.get(
      "v1/public/eco-crop/eco-crop-type?page=1&per_page=5000"
    );

    console.log(res.data, "res");

    const sortedData = res.data.data.sort((a: any, b: any) =>
      a.localeCompare(b)
    );
    // console.log(sortedCrops, "sort");
    const cropData = sortedData.map((item: any) => {
      return {
        name: item.charAt(0).toUpperCase() + item.slice(1),
        value: item,
      };
    });
    console.log(cropData, "cropdata");
    setAllCrops(cropData);
  }

  const waterSource = [
    { name: "Rainfed", value: "rainfed" },
    { name: "Rainfed & Irrigation", value: "rainfed_irrigation" },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 400); // Match this duration with the CSS transition duration
    }, 4000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  useEffect(() => {
    fetchCrops();
    getLivestock();
  }, []);

  useEffect(() => {
    // Reset currentAnswer when moving to a new question
    setCurrentAnswer("");
  }, [currentQuestionIndex]);
  const customStyles = {
    control: (provided) => ({
      ...provided,

      //for mobile css end
      paddingLeft: "2rem", // pl-10
      paddingRight: "0.5rem", // pr-4
      paddingTop: isMobile ? "0.2px" : "0.4rem", // py-4 (top)
      paddingBottom: isMobile ? "0.2px" : "0.4rem", // py-4 (bottom)
      borderColor: "#D1D5DB", // Tailwind gray-300 border color
      borderRadius: "9999px", // rounded-full
      width: "100%", // w-full
      height: isMobile ? "32px" : "", // w-full
      minHeight: isMobile ? "32px" : "", // w-full
      color: "#9CA3AF", // text-gray-400
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#9CA3AF", // text-gray-400
    }),
    menu: (provided) => ({
      ...provided,
      // zIndex: 50, // z-50 to ensure dropdown visibility
    }),
  };
  const handleMonthsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputMonths = e.target.value;
    setMonths(inputMonths);

    if (inputMonths && !isNaN(Number(inputMonths))) {
      const numberOfMonths = parseInt(inputMonths, 10);
      const newStartDate = new Date(); // Use current date as start date
      const newEndDate = new Date(newStartDate);
      newEndDate.setMonth(newEndDate.getMonth() + numberOfMonths);

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      // Reset dates if input is invalid
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };
  const handlelosePopUp = (e: any) => {
    if (e.target.id === "ModelContainer") {
      setOpenMapPopup(false);
    }
  };

  console.log(selected, " submit state");
  async function getCompositeScore() {
    try {
      setLoading(true);
      setOpenCapPopup(true);
      const res = await baseInstance.post(COMPUTE_SCORE_CROP, {
        crop: selectedCrop,
        latitude: selected?.lat,
        longitude: selected?.lng,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        locationName: address,
        water_source: source,
      });
      setLoading(false);
      console.log(res.data, " res");
      setReportId(res.data.report_id);
      setReportData(res.data);
      setIsModalOpen(true);
      setOpenCapPopup(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong ",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
      setOpenCapPopup(false);
    }
  }
  async function getLivestock() {
    const GET_LIVESTOCK = `/v1/public/livestock/livestock-type`;
    baseInstance({ method: "GET", url: GET_LIVESTOCK })
      .then((data) => {
        console.log(data.data, "all livestock data");
        const resultArray = [];
        const fullData = data.data;
        for (const key of Object.keys(fullData)) {
          resultArray.push({ label: fullData[key], value: key });
        }
        setLivestock(resultArray);
        // });
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }

  const handleSubmit = async (state) => {
    setOpen(false);
    setOpenCapPopup(true);

    const body = {
      livestock_type: option.value,
      parameters: { ...answers },
    };

    body.report_id = reportId;

    body.parameters.system = firstAnswer;

    body.session_id = sessionId;
    console.log(body, "body");

    const URL_PROFIT = `/v1/public/livestock/compute-profitability-risk`;
    setIsLoading(true);
    baseInstance<any>({
      method: "POST",
      url: URL_PROFIT,
      data: body,
    })
      .then((data) => {
        console.log(data?.data, "score data");
        setOpenCapPopup(false);
        setReportIdLivestock(data.data.report_id);
        setProfitData(data?.data);
        setIsComputed(true);
        setIsLoading(false);
        setIsModalOpen(true);
        const specificScores = [
          "water_scores",
          "profitability_scores",
          "climate_scores",
        ];
        const result = [];
      })
      .catch(() => setOpenCapPopup(false));
    // setOption(null);
    setSumbit({});
    setStep(0);
    setQuestions([]);
  };

  async function handleChangeFirstQuestion(item) {
    setCurrentQuestionIndex(0);
    setAnswers({});
    setIsSubmitting(false);
    setSubmitMessage("");
    setDebugInfo("");

    setFirstAnswer(item.value);
    setSumbit({ ...sumbmit, [type.current]: item.value });

    setError(null);
    setOpenModal(false);
    const REST_QUESTIONS = "/v1/public/livestock/questionnaire/others";
    const payload = {
      livestock_type: selectedItem,
      system: item.value,
    };
    baseInstance<any>({
      method: "POST",
      url: REST_QUESTIONS,
      data: payload,
    })
      .then((data) => {
        console.log(data, "question data");

        setQuestionnaires(data?.data?.questionnaires);
        setOpen(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }

  const handleVerify = (token) => {
    setTurnstileToken(token);
    console.log("Turnstile token:", token);
    // You can now send this token to your backend for verification
  };
  function getFormQuestions(type) {
    const CHECK_SCORE = `/v1/public/livestock/compute-climate-water-risk`;
    // const URL_Questions = `/questionnaire/system-only/${type}`;
    const URL_Questions = `/v1/public/livestock/questionnaire/system-only/${type}`;

    console.log(type, "submit data for now");

    // "endDate": endDate,
    // "latitude": -0.023559,
    // "livestock_type": "dairy_cattle",
    // "locationName": "Kenya",
    // "longitude": 37.906193,
    // "startDate": "2024-03-14"
    const payload = {
      crop: selectedCrop,
      latitude: selected?.lat,
      longitude: selected?.lng,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      locationName: address,
    };

    delete payload.crop;
    payload.livestock_type = type;
    Swal.fire({
      title: "Evaluating location",
      html: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    baseInstance<any>({ method: "POST", url: CHECK_SCORE, data: payload })
      .then((data) => {
        Swal.close();
        console.log(data?.data?.report_id, "warning score livestock");

        setReportId(data?.data?.report_id);
        setSessionId(data?.data?.session_id);

        // if (
        //   Math.floor(
        //     data?.data?.total_scores?.climate_scores?.composite_climate_risk
        //   ) < 74 &&
        //   Math.floor(
        //     data?.data?.total_scores?.water_scores?.composite_water_risk
        //   ) < 74
        // ) {
        setOpenModal(true);
        setIsLoading(true);
        baseInstance<any>({ method: "GET", url: URL_Questions })
          .then((data) => {
            console.log(data, "question data");

            setQuestions(data?.data?.questionnaires);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("API Error:", error);
          });
        // }

        if (
          Math.floor(
            data?.data?.total_scores?.climate_scores?.composite_climate_risk
          ) > 74 &&
          Math.floor(
            data?.data?.total_scores?.water_scores?.composite_water_risk
          ) > 74
        ) {
          Swal.fire({
            icon: "error",
            title: "<h5 style='color:red'>" + "Error" + "</h5>",
            text: "The Climate and Water Risk is too high. Do not proceed!",
          });
          return;
        }
        if (
          Math.floor(
            data?.data?.total_scores?.water_scores?.composite_water_risk
          ) > 74
        ) {
          Swal.fire({
            icon: "error",
            title: "<h5 style='color:#f27474'>" + "Error" + "</h5>",
            text: "The Water Risk is too high! Do not proceed!",
          });
          return;
        }

        if (
          Math.floor(
            data?.data?.total_scores?.climate_scores?.composite_climate_risk
          ) > 74
        ) {
          Swal.fire({
            icon: "warning",
            title: "<h5 style='color:orange'>" + "Warning." + "</h5>",
            text: "The area you selected has very high climate  risk. Do you still want to compute the score?",
            showDenyButton: true,
            // showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
          }).then((result) => {
            if (result.isConfirmed) {
              setIsLoading(true);
              setOpenModal(true);
              axiosProfitablity<any>({ method: "GET", url: URL_Questions })
                .then((data) => {
                  console.log(data, "question data");

                  setFirstQuestion(data?.data?.questionnaires);
                  setIsLoading(false);
                })
                .catch((error) => {
                  console.error("API Error:", error);
                });
            }

            if (result.isDenied || result.isDismissed) {
              return;
            }
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }
  const handleCheckboxChange = (event) => {
    setIsLivestock(event.target.checked);
  };

  const handleChanget = (item) => {
    setOption(item);
  };
  const handlePrevious = () => {
    if (!isFirstQuestion) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const renderQuestionInput = () => {
    if (!currentQuestion) {
      console.error("Current question is undefined");
      return <p>Error: Question not found</p>;
    }

    if (currentQuestion?.choices && currentQuestion?.choices.length > 0) {
      const options = currentQuestion?.choices.map((choice) => ({
        value: choice,
        label: choice,
      }));

      return (
        <Select
          options={options}
          onChange={(selectedOption) =>
            handleAnswer(selectedOption ? selectedOption.value : "")
          }
          value={
            options.find(
              (option) =>
                option.value === answers[currentQuestion?.category_type]
            ) || null
          }
          isClearable={true}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
              color: "black",
            }),
          }}
        />
      );
    } else {
      return (
        <input
          type="number"
          value={answers[currentQuestion?.category_type] || ""}
          onChange={(e) => handleAnswer(Number(e.target.value))}
          placeholder="Enter a number"
        />
      );
    }
  };

  console.log(questionnaires, "da");
  return (
    <>
      <div className="lg:flex block mt-1 md:mt-10 relative">
        <div className="md:relative bnr-f-t w-100 lg:w-[90%] text-center lg:text-left">
          <div className=" bnr-text">
            <h1
              className="pb-3 text-white font-extrabold md:font-bold
             text-[22px] lg:text-[40px] xl:text-5xl"
            >
              ADAPTA CLIMATE SCORE
            </h1>
            <p className="text-white  ffont-medium text-[10px] md:text-[18px]">
              ADAPTA's Climate Report helps you understand a farm's climate
              risks and production potential for popular crops and livestock.
            </p>
          </div>
          <div className=" md:mb-4 border mt-[25px] p-[10px] form-bg md:border-blue-100 md:p-[20px] flex flex-col gap-y-3 md:gap-y-5 justify-center rounded-2xl w-[100%] lg:min-w-[70%]">
            <p className="text-white text-[22px]  md:text-3xl font-semibold text-center">
              GET YOUR CLIMATE SCORE
            </p>

            <div className="flex justify-center gap-x-3">
              <p className="text-white">Crops</p>
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  id="switch"
                  checked={isLivestock}
                  onChange={handleCheckboxChange}
                  type="checkbox"
                  className="peer sr-only "
                />
                <label htmlFor="switch" className="hidden"></label>
                <div className="peer h-6 w-11 rounded-full border bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-slate-800 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
              </label>
              <p>Livestock</p>
            </div>
            <div className="flex flex-col  gap-y-2 md:gap-y-3  ">
              <div className="relative ">
                <input
                  value={address}
                  onFocus={() => setOpenMapPopup(true)}
                  type="text"
                  className="pl-10 pr-4 py-[0.2rem] md:py-3 text-[12px]  border rounded-full  w-full"
                  placeholder="Enter Your Location"
                />
                <div
                  className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                >
                  <img loading="lazy" src={location} alt="location icon" />
                </div>
              </div>
              <div className="relative ">
                <input
                  type="number"
                  onChange={handleMonthsChange}
                  className="pl-10 pr-4 py-[0.2rem] md:py-3 text-[12px]  border rounded-full  w-full"
                  placeholder="Enter Time (In Months)"
                />
                <div
                  className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                >
                  <img loading="lazy" src={time} alt="location icon" />
                </div>
              </div>

              {isLivestock ? (
                <div className="relative">
                  <Select
                    placeholder="Select a livestock"
                    value={option}
                    className="text-black text-[12px] text-left border-[0.1px] rounded-full border-[#565656]"
                    styles={customStyles}
                    onChange={(item) => {
                      console.log(item);
                      handleChanget(item);
                      setSelectedItem(item.value);
                      getFormQuestions(item.value);

                      // setOpen(true);
                    }}
                    options={livestock}
                  />
                  <div
                    className="absolute inset-y-0 left-0 pl-3  
                     flex items-center  
                     pointer-events-none"
                  >
                    <img loading="lazy" src={cropsImg} alt="location icon" />
                  </div>
                </div>
              ) : (
                <>
                  <div className="relative">
                    <select
                      onChange={(e) => setSelectedCrop(e.target.value)}
                      value={selectedCrop}
                      className="pl-10 pr-4 py-[0.2rem] md:py-3 text-[12px] text-[#6B7280]  border rounded-full  w-full"
                    >
                      <option value="">Select Crop</option>
                      {allCrops?.map((cropOption) => (
                        //@ts-ignore
                        <option key={cropOption.name} value={cropOption.value}>
                          {cropOption.name}
                        </option>
                      ))}
                    </select>
                    <div
                      className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                    >
                      <img loading="lazy" src={cropsImg} alt="location icon" />
                    </div>
                  </div>
                  <div className="relative">
                    <select
                      onChange={(e) => setSource(e.target.value)}
                      value={source}
                      className="pl-10 pr-4 py-[0.2rem] md:py-3 text-[12px] text-[#6B7280]  border rounded-full  w-full"
                    >
                      <option value="">Select Water Source</option>
                      {waterSource?.map((waterOption) => (
                        //@ts-ignore
                        <option
                          key={waterOption.name}
                          value={waterOption.value}
                        >
                          {waterOption.name}
                        </option>
                      ))}
                    </select>
                    <div
                      className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
                    >
                      <img loading="lazy" src={cropsImg} alt="location icon" />
                    </div>
                  </div>
                </>
              )}
              {/* <div className="w-[200px] captcha-d overflow-hidden">
                <FormCaptcha />
              </div> */}
              <button
                onClick={getCompositeScore}
                disabled={loading || isProtected}
                className={`w-full py-[0.5rem] md:py-4 text-[12px] rounded-full bg-[#262626] text-white ${
                  loading ? "opacity-70 cursor-not-allowed" : ""
                }`}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Loading...
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
        <div className={`hero ${fade ? "fade-in" : "fade-out"} block h-fit `}>
          <img loading="lazy" src={images[currentImageIndex]} alt="Hero" />
        </div>

        {openMapPopup === true ? (
          <div
            // style={{ zIndex: "99999" }}
            id="ModelContainer"
            onClick={handlelosePopUp}
            className="fixed inset-0 bg-black flex justify-center items-center bg-opacity-20 backdrop-blur-sm "
          >
            <div className="relative p-2 m-5 px-7 bg-white w-[90%] md:w-[60%]  h-[70vh] flex justify-between flex-col text-center shadow-inner border-e-emerald-600 rounded-lg py-5">
              <div
                onClick={() => setOpenMapPopup(false)}
                className="absolute right-3 top-3 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </div>

              <Places
                setIsProtected={setIsProtected}
                address={address}
                setOpenMapPopup={setOpenMapPopup}
                selected={selected}
                setSelected={setSelected}
                setAddress={setAddress}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {openCapPopup === true ? (
          <div
            // style={{ zIndex: "99999" }}
            id="ModelContainer"
            onClick={() => setOpenCapPopup(false)}
            className="fixed inset-0 bg-black flex justify-center items-center bg-opacity-20 backdrop-blur-sm "
          >
            <div className="relative p-2 m-2 md:m-5 md:px-7 bg-white w-[350px]  flex justify-between flex-col text-center shadow-inner border-e-emerald-600 rounded-lg py-5 pt-6">
              <div
                onClick={() => setOpenMapPopup(false)}
                className="absolute right-3 top-3 cursor-pointer"
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg> */}
              </div>
              <FormCaptcha />
            </div>
          </div>
        ) : (
          <></>
        )}
        <Modal
          onClose={() => {
            setStep(0);
            type.current = null;
            selectedOption.current = null;
            setSumbit({});
            setOpen(false);
            setOption(null);
          }}
          show={open}
        >
          <Modal.Header className="items-center">
            Please answer the following questions
          </Modal.Header>
          <Modal.Body className="overflow-visible min-h-[200px]">
            {isLoading ? (
              <>
                {/* <BeatLoader
                  color={"#87CEEB"}
                  // loading={loading}
                  cssOverride={override}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                /> */}
                Loading...
              </>
            ) : (
              <div className="space-y-6">
                <p className="mb-4">{currentQuestion?.question}</p>
                <div className="text-sm text-gray-500 mb-2">
                  {`Question ${currentQuestionIndex + 1} of ${
                    questionnaires.length
                  }`}
                </div>
                {renderQuestionInput()}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="py-3">
            <div className="flex justify-between items-center w-full">
              {/* <Button
                disabled={step === 0}
                className="bg-transparent text-[#0e7490] border border-cyan-700 hover:text-white"
                onClick={handlePrev}
              >
                Prev
              </Button>
              {step === questions?.length - 1 ? (
                <Button
                  color="gray"
                  className="bg-cyan-700 text-white"
                  onClick={handlesub}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  color="gray"
                  className="bg-cyan-700 text-white"
                  onClick={handleNext}
                >
                  Next
                </Button>
              )} */}
              <Button
                onClick={handlePrevious}
                disabled={currentQuestionIndex === 0}
              >
                Previous
              </Button>
              {isLastQuestion ? (
                <Button onClick={handleSubmit} disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              ) : (
                <Button onClick={handleNext}>Next</Button>
              )}
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          onClose={() => {
            setOpenModal(false);
          }}
          show={openModal}
        >
          <Modal.Header className="items-center">
            How do you keep your animals
          </Modal.Header>
          <Modal.Body className="min-h-[200px]" style={{ overflow: "visible" }}>
            {isLoading ? (
              <>
                {/* <BeatLoader
                  color={"#87CEEB"}
                  // loading={loading}
                  cssOverride={override}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                /> */}
                Loading...
              </>
            ) : (
              <div className="space-y-6">
                {questions?.map((question, index) => {
                  const choicesObjects = question?.choices?.map((choice) => ({
                    label: choice,
                    value: choice,
                  }));
                  if (step === index) {
                    type.current = question.category_type;
                  }

                  return (
                    <>
                      {step === index && (
                        <>
                          <div key={index} className="font-regular">
                            You will get further question based on this answer
                          </div>
                          {/* <div className="font-semibold">
                            <p className="mb-4">{currentQuestion?.question}</p>
                          </div> */}
                          <div className="z-10 mt-3">
                            {choicesObjects.length === 0 ? (
                              <input
                                type="number"
                                className="border border-gray-300 p-2 rounded-md dark:text-black "
                                value={inputValue}
                                onChange={(e) => setinputValue(e.target.value)}
                              />
                            ) : (
                              <Select
                                className="dark:text-black"
                                value={
                                  (selectedOption.current && {
                                    value: selectedOption.current.value,
                                    label: selectedOption.current.label,
                                  }) ||
                                  (sumbmit[type.current] && {
                                    value: sumbmit[type.current],
                                    label: sumbmit[type.current],
                                  })
                                }
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                    color: "black",
                                  }),
                                }}
                                options={choicesObjects}
                                onChange={handleChangeFirstQuestion}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="py-3"></Modal.Footer>
        </Modal>
        <ModalSelf isOpen={isModalOpen}>
          <div className="w-[80%] md:w-[60%] h-full fixed  top-0 mx-auto left-[50%] scrollbar-hide translate-x-[-50%] z-[60] overflow-x-hidden overflow-y-auto">
            <div className="flex flex-col bg-white border shadow-sm rounded-xl ">
              <div className="flex justify-between items-center py-3 px-4 border-b ">
                <h3 className="font-bold text-gray-800 ">Full Report</h3>
                <div>
                  {/* Col */}
                  <div className="inline-flex gap-x-2 mr-5"></div>
                  {/* Col */}
                  <button
                    onClick={() => setIsModalOpen(false)}
                    type="button"
                    className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm  "
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="w-3.5 h-3.5"
                      width={8}
                      height={8}
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="p-4 md:h-screen overflow-y-auto">
                {isLivestock ? (
                  <PartialReportLivestock
                    reportId={reportIdLivestock}
                    setIsModalOpen={setIsModalOpen}
                    reportData={profitData}
                  />
                ) : (
                  <PartialReport
                    reportId={reportId}
                    setIsModalOpen={setIsModalOpen}
                    reportData={reportData}
                  />
                )}
              </div>
            </div>
          </div>
        </ModalSelf>
      </div>
    </>
  );
};
