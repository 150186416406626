//@ts-nocheck
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
// import styles from "../css/phoneInput.module.css";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import baseInstance from "../api";
const schema = yup.object().shape({
  requester_name: yup.string().required("Name is required"),
  requester_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  requester_phone_number: yup.string().required("required"),
  // .min(10, "too short")
  // .max(14, "too long"),
  // requester_company: yup.string()?.required("Company name is required"),
  // requester_company_website: yup
  //   .string()
  //   .url("Invalid URL")
  //   .required("Company website is required"),
  message: yup.string().required("Message is required"),
});

export default function ContactUs() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: any) => {
    console.log("running");
    // const subject = `Contact us from ${data.name} from ${data.companyName}`;
    data.request_type = "INFO";
    console.log(data, "contact");
    try {
      // setFormData((prevData) => ({
      //   ...prevData,
      //   subject: subject,
      //   email: data.email,
      //   message: data.message,
      //   name: data.name,
      //   phone: data.phone,
      //   companyName: data.companyName,
      //   companyWebsite: data.companyWebsite,
      // }));

      const response = await baseInstance({
        method: "POST",
        url: "/emails/landing-page/request",
        data,
      });
      const responseData = response.data;
      reset();
      toast.success(responseData.message);
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
      console.error("Error submitting form:", error);
    }
  };
  return (
    <div className=" h-full w-full py-24">
      {/* <div className="text-center py-20">
        <div className="text-[14px]">HOME / CONTACT US</div>
        <div className="text-[40px] font-semibold line-[70] ">CONTACT US</div>
      </div> */}
      <div className="md:container md:px-[122px] px-[30px] flex flex-col gap-10 md:flex-row text-center ">
        {/* <img className="h-[405px] w-[331px]" src="./contactus.png" /> */}
        <div className="w-full mt-[-10px]">
          <div className="flex flex-col">
            <div className="text-[24px] md:text-[50px] font-black line-[70]">
              We thought you’d Never ask!
              <span className="text-[#338FD9]">Let’s talk!</span>
            </div>
          </div>

          <form className="space-y-4 " onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                {...register("requester_name")}
                placeholder="Name"
                className="w-full sm:w-1/2 text-slate-600 bg-white border border-[#338FD9] rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#338FD9] focus:ring-opacity-30"
              />
              <input
                {...register("requester_email")}
                placeholder="Email"
                className="w-full sm:w-1/2 text-slate-600 bg-white border border-[#338FD9]  rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#008D06] focus:ring-opacity-30"
              />
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
              <Controller
                control={control}
                name="requester_phone_number"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <PhoneInput
                    className="w-full"
                    defaultCountry="ke"
                    inputClassName="w-full"
                    name="phone"
                    onChange={onChange}
                  />
                )}
              />
              {/* <input
                      placeholder="Subject"
                      className="w-full sm:w-1/2 text-slate-600 bg-white border border-[#008D06] rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#008D06] focus:ring-opacity-30"
                    /> */}
            </div>
            <textarea
              className="w-full text-slate-600 bg-white border border-[#338FD9]  rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#008D06] focus:ring-opacity-30"
              name="message"
              {...register("message")}
              id="message"
              placeholder="Your request..."
              rows="4"
              required
            ></textarea>
            <button
              omClick={handleSubmit(onSubmit)}
              type="submit"
              className="inline-block w-full md:w-fit px-6 py-2 rounded-[26px] bg-[#338FD9] text-white text-sm sm:text-base hover:bg-[#006D04] transition-colors duration-300"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
