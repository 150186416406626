import { useCallback } from "react";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";
const stripeKey = import.meta.env.VITE_STRIPE_KEY;
const stripeRedirect = import.meta.env.VITE_STRIPE_REDIRECT;
const stripePromise = loadStripe(stripeKey);
const base_url = import.meta.env.VITE_API_URL;
const Checkout = () => {
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get("reportId");
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return fetch(
      `${base_url}/v1/public/eco-crop/create-checkout-session-embedded`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          report_id: reportId,
          base_url: stripeRedirect,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default Checkout;
