import { useState, useEffect } from "react";
import { X, ShoppingBag } from "lucide-react";

const PromoSlide = ({ setOpenMapPopup }: any) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Slight delay before showing the banner
    const showTimer = setTimeout(() => setIsVisible(true), 500);

    // Hide banner after 10 seconds
    const hideTimer = setTimeout(() => setIsVisible(false), 10500);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  return (
    <div
      className={`fixed top-1/4 right-0 z-50 w-64 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-l-lg shadow-lg transition-transform duration-500 ease-in-out ${
        isVisible ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="p-4">
        <button
          onClick={() => {
            setIsVisible(false);
          }}
          className="absolute top-2 right-2 text-white hover:text-purple-200 transition-colors"
          aria-label="Close banner"
        >
          <X size={20} />
        </button>
        <h3 className="font-bold text-lg mb-2">LIMITED TIME OFFER</h3>
        <p className="text-sm mb-4">
          All reports purchased will be free . Get yours before it's too late.
        </p>
        <button
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth", // This makes the scroll smooth. Omit for instant scrolling.
            });
            setIsVisible(false);
            setOpenMapPopup(true);
          }}
          className="inline-flex items-center bg-white text-purple-600 px-4 py-2 rounded-full font-semibold text-sm hover:bg-purple-100 transition-colors"
        >
          <ShoppingBag size={16} className="mr-2" />
          Buy Now
        </button>
      </div>
    </div>
  );
};

export default PromoSlide;
