//@ts-nocheck

// src/Banner.jsx
import { useEffect, useState } from "react";

export function cookieConsentGiven() {
  if (!localStorage.getItem('cookie_consent')) {
    return 'undecided';
  }
  return localStorage.getItem('cookie_consent');
}

export function Banner() {
  const [consentGiven, setConsentGiven] = useState('');

  useEffect(() => {
    setConsentGiven(cookieConsentGiven());
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookie_consent', 'yes');
    setConsentGiven('yes');
  };

  const handleDeclineCookies = () => {
    localStorage.setItem('cookie_consent', 'no');
    setConsentGiven('no');
  };

  return (
    <div>
      {consentGiven === 'undecided' && (
          <div className="fixed bottom-0 right-0 z-[60] sm:max-w-xl w-full mx-auto p-6">
          {/* Card */}
          <div class="bg-white rounded-lg shadow-lg p-4">
        <div class="flex items-center justify-between mb-2">
            <div class="flex items-center">
                <img src="https://www.svgrepo.com/show/401340/cookie.svg" alt="Cookie" class="h-6 w-6 mr-2"/>
                <span class="text-gray-700 font-bold text-sm">Cookie Policy</span>
            </div>
            <button onClick={handleDeclineCookies} class="text-gray-500 hover:text-gray-700 focus:outline-none">
                <svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                    </path>
                </svg>
            </button>
        </div>
        <p class="text-gray-600 text-sm">
            We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of
            cookies. <span className=" text-blue-600  hover:underline" onClick={()=> window.open("https://www.iubenda.com/privacy-policy/12212628/cookie-policy", "_blank", "noreferrer")}>Read more</span>
        </p>
        <button onClick={handleAcceptCookies} class="mt-4 bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded">
            Accept
        </button>
    </div>
          {/* End Card */}
        </div>
      )}
    </div>
  )
}