//@ts-nocheck
import ProgressBar from "./ProgressBar";
import { useNavigate } from "react-router-dom";

import GaugePdf from "./GaugePdf";
import logo from "../assets/images/logo.png";
import { useLoadScript } from "@react-google-maps/api";
import { Map, MapForReport } from "./PlacesAutocomplete";
// interface RiskDataProps {
//   myRiskdata: TotalScores;
//   loanPeriod: string;
//   crop: string;
//   myLocation: {
//     lat: number;
//     lng: number;
//   };
//   locationScoreId: string;
// }

// interface FormData {
//   debtor_name: string;
//   debtor_contacts: string;
//   reviewer_comments: string;
//   location_score_id: string;
//   saved: boolean;
//   location_name: string;
// }
//@ts-ignore
const climate_indices = ["Drought", "Precipitation", "Temperature"];
//const water_indices = ["Groundwater", "Water Erosion", "Water Stress"];
//const soil_indices = ["Top Soil Fertility", "Soil pH", "Nutrient capacity"];
const PartialReport = ({ reportData, reportId }: any) => {
  console.log(reportId, "reportId");
  const navigate = useNavigate();
  // const oid = localStorage.getItem("oid");
  console.log(reportData, "report data");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCEVC1A3YRL-S4zGuuLgHnlYjlQr90P5iA",
    libraries: ["places"],
  });
  // const redirectToCheckout = () => {
  //   navigate({
  //     pathname: "/verify",
  //     search: `?reportId=${reportId}`,
  //   });
  // };
  const redirectToCheckout = () => {
    const url = `/verify?reportId=${reportId}`;
    window.open(url, "_blank");
  };
  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Difference in milliseconds
    const differenceInTime = end - start;

    // Convert time difference to days, months, and years
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const differenceInMonths = differenceInDays / 30.44; // Approximate months
    const differenceInYears = differenceInMonths / 12;

    return {
      days: Math.floor(differenceInDays),
      months: Math.floor(differenceInMonths),
      years: differenceInYears,
    };
  };

  const { months } = calculateDateDifference(
    reportData.startDate,
    reportData.endDate
  );
  // const PROFILE_URL = `/risk/v1/location_scores/${locationScoreId}/update`;

  // const groupedSuggestions = Object.values(myRiskdata?.adaptations)?.reduce(
  //   (acc, suggestion) => {
  //     const pillar = suggestion.Pillar;
  //     if (!acc[pillar]) {
  //       acc[pillar] = [];
  //     }
  //     acc[pillar].push({
  //       Index: suggestion.Index,
  //       Suggestion: suggestion.Suggestion,
  //     });
  //     return acc;
  //   },
  //   {}
  // );
  // console.log(groupedSuggestions, "groupsuggestion");

  return (
    <>
      {/* Invoice */}
      <div
        id="printablediv"
        className="max-w-[85rem] md:h-100 md:overflow-auto mx-auto "
      >
        <form>
          {/* Grid */}
          <div className="flex justify-center items-center  border-gray-200  ">
            <div>
              <img alt="Logo" className="w-36 mx-auto" src={logo} />
            </div>
          </div>

          {/* End Grid */}
          <div className="pt-6 px-4 sm:px-6 lg:px-8">
            {/* Grid */}

            {/* End Grid */}

            <div className=""></div>
            {/* Table */}
            <div className="grid  grid-cols-1 lg:grid-cols-2 gap-8 border border-gray-200 p-4 rounded-lg  ">
              <div className="h-[300px] md:h-[100%] ">
                <h3 className="mb-3 font-semibold text-gray-800  ">Map</h3>

                {isLoaded ? (
                  <MapForReport
                    lat={reportData?.latitude}
                    lng={reportData?.longitude}
                  />
                ) : (
                  <>Loading ...</>
                )}
              </div>
              <div className="flex flex-col justify-between gap-8  items-center ">
                <h3 className="mb-1  font-semibold text-gray-800 ">
                  Composite Risk Score
                </h3>
                {/* <Gauge level={ /> */}
                <ProgressBar
                  isBig={true}
                  progress={Math.floor(reportData?.composite_total_risk)}
                  width={"100%"}
                />

                <div className="w-[100%]">
                  <div className="w-full">
                    <h4 className="text-xs font-semibold uppercase text-gray-800 ">
                      Summary
                    </h4>
                    <ul className="mt-3 flex flex-col bg-[#FAFAFA]">
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                        <div className="flex items-center justify-between w-full">
                          <span>Time in months</span>
                          <span>{months + 1}</span>
                        </div>
                      </li>
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg  ">
                        <div className="flex items-center justify-between w-full">
                          <span>Crop</span>
                          <span>{reportData?.crop}</span>
                        </div>
                      </li>
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg  ">
                        <div className="flex items-center justify-between w-full">
                          <span>Biodiversity</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="green"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="lucide lucide-badge-check"
                          >
                            <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                            <path d="m9 12 2 2 4-4" />
                          </svg>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* End Table */}

            <div className=" mt-4 relative">
              <button
                onClick={redirectToCheckout}
                className=" left-[25%] md:top-[180px] md:right-[40%] z-50 absolute h-10 p-4 inline-flex justify-right w-fit ml-auto items-center gap-2 rounded-md border border-transparent font-semibold bg-[#58BC61] text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm "
              >
                Buy Full Report
              </button>
              <div className="grid grid-cols-1 gap-6 mb-16  ">
                Climate Risk
                <div className="flex p-4 items-center py-6 bg-[#F9F9F9] blur-sm">
                  <GaugePdf level={20} />
                  <ProgressBar width={"25%"} progress={33} />

                  <ProgressBar width={"25%"} progress={90} />

                  <ProgressBar width={"25%"} progress={45} />
                </div>
                Water Risk
                <div className="flex p-4 py-6 items-center bg-[#F9F9F9] blur-sm">
                  <GaugePdf level={50} />
                  <ProgressBar width={"25%"} progress={33} />
                  <ProgressBar width={"25%"} progress={56} />
                  <ProgressBar width={"25%"} progress={77} />
                </div>
                Soil Risk
                <div className="flex p-4 py-6 items-center  bg-[#F9F9F9] blur-sm">
                  <GaugePdf level={55} />
                  <ProgressBar width={"25%"} progress={33} />
                  <ProgressBar width={"25%"} progress={44} />
                  <ProgressBar width={"25%"} progress={40} />
                </div>
                {/* <TopGauge
                pillar="CLIMATE"
                rainfall_risk={myRiskdata?.climate_scores.drought_risk}
                //@ts-ignore
                temperature_risk={myRiskdata?.climate_scores.precipitation_risk}
                //@ts-ignore
                drought_risk={myRiskdata?.climate_scores.temperature_risk}
                composite_climate_risk={
                  myRiskdata?.climate_scores.composite_climate_risk
                }
                categories={climate_indices}
              /> */}
                {/* <TopGauge
                pillar="WATER"
                rainfall_risk={myRiskdata?.water_scores.ground_water_risk}
                temperature_risk={
                  myRiskdata?.water_scores.rainfall_erosivity_risk
                }
                drought_risk={myRiskdata?.water_scores.location_aquaduct_risk}
                composite_climate_risk={
                  myRiskdata?.water_scores.composite_water_risk
                }
                categories={water_indices}
              /> */}
                {/* <TopGauge
                pillar="SOIL"
                rainfall_risk={myRiskdata?.soil_scores.soil_organic_carbon_risk}
                temperature_risk={myRiskdata?.soil_scores.soil_ph_risk}
                drought_risk={
                  myRiskdata?.soil_scores.cation_exchange_capacity_risk
                }
                composite_climate_risk={
                  myRiskdata?.soil_scores.composite_soil_risk
                }
                categories={soil_indices}
              /> */}
              </div>
            </div>
            <div className="mt-5">
              <h4 className="text-2xl mb-2 font-semibold text-gray-800 ">
                Climate Adaptation Suggestions To Improve Your Score
              </h4>
              <div className="blur-sm">
                <h4 className="text-[#338FD9] text-xl">Climate</h4>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <h4 className="text-[#338FD9] text-xl">Soil</h4>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
                <p className="text-sm font-medium mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  molestias sed veritatis quia delectus. Error hic doloremque
                  alias repudiandae facilis. jfklasdfjsdkl f kdfj k j kfjksdj
                  flsdj kjk kdj fj k kjksldj fkljsdkla sda f d fdfksdjakl .
                </p>
              </div>
            </div>
            <div className="mt-8">
              <h4 className="text-lg font-semibold text-gray-800 ">
                Thank you!
              </h4>
              <p className="text-gray-500">
                If you have any questions concerning this report, use the
                following contact information:
              </p>
              <div className="mt-2">
                <p className="block text-sm font-medium text-gray-800 ">
                  info@adapta.earth
                </p>
              </div>
            </div>
            <p className="mt-5 text-sm text-gray-500">© 2023 ADAPTA.</p>
          </div>
        </form>
        <div className="flex"></div>
      </div>
      {/* End Invoice */}
    </>
  );
};

export default PartialReport;
