import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import { AboutUs } from "./pages/AboutUs";
import { ContactUs } from "./pages/ContactUs";
import Help from "./pages/Help";
import { Banner } from "./components/CookieBanner";
import Checkout from "./pages/Checkout";
import Success from "./pages/Success";
import CountryPrices from "./pages/CountryPrices";
import CheckoutLivestock from "./pages/CheckoutLivestock";
import SuccessLivestock from "./pages/SuccessLivestock";
import { Toaster } from "react-hot-toast";
import VerifyHuman from "./pages/VerifyHuman";
import VerifyHumanLivestock from "./pages/VerifyHumanLivestock";
import Survey from "./pages/Survey";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },

  {
    path: "/help",
    element: <Help />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/checkout-livestock",
    element: <CheckoutLivestock />,
  },
  {
    path: "success",
    element: <Success />,
  },
  {
    path: "success-livestock",
    element: <SuccessLivestock />,
  },
  {
    path: "/prices-breakdown",
    element: <CountryPrices />,
  },
  {
    path: "/verify",
    element: <VerifyHuman />,
  },
  {
    path: "/verify-livestock",
    element: <VerifyHumanLivestock />,
  },
  {
    path: "/survey",
    element: <Survey />,
  },
]);
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Toaster />

    <RouterProvider router={router} />
    <Banner />
  </React.StrictMode>
);
