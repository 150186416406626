const LegalDisclamer = () => {
  return (
    <div className="py-20 text-center">
      <h4 className="font-black text-[24px] md:text-[50px]">
        LEGAL DISCLAIMER
      </h4>
      <p className="text-lg leading-8 text-[#7D7B7B] mt-4">
        Users acknowledge and accept that by providing their own content on this
        Application they grant the Owner a non-exclusive, fully paid-up and
        royalty-free license to process such content solely for the operation
        and maintenance of this Application as contractually required. To the
        extent permitted by applicable law, Users waive any moral rights in
        connection with content they provide to this Application.
        <button
          className="font-medium text-blue-600  hover:underline"
          onClick={() =>
            window.open(
              "https://www.iubenda.com/terms-and-conditions/12212628",
              "_blank",
              "noreferrer"
            )
          }
        >
          {" "}
          Read more ...
        </button>
      </p>
    </div>
  );
};

export default LegalDisclamer;
