//@ts-nocheck
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import baseInstance from "../api";
import { MapForReport } from "../components/PlacesAutocomplete";
import ProgressBar from "../components/ProgressBar";
import GaugePdf from "../components/GaugePdf";
import { useLoadScript } from "@react-google-maps/api";
import generatePDF from "react-to-pdf";
import { useRef } from "react";
const SuccessLivestock = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [myRiskdata, setMyRiskData] = useState(null);
  const [loading, setLoading] = useState(true);
  const reportId = searchParams.get("report_id");
  const sessionId = searchParams.get("session_id");
  const targetRef = useRef();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCEVC1A3YRL-S4zGuuLgHnlYjlQr90P5iA",
    libraries: ["places"],
  });
  const climate_indices = ["Drought", "Temperature humidity"];
  const water_indices = ["Water stress", "Ground Water Availability"];
  const profitability_indices = ["Profitability"];
  async function getFullReport() {
    setLoading(true);
    try {
      const res = await baseInstance.get(
        `/v1/public/livestock/full-report/success?report_id=${reportId}&checkout_session_id=${sessionId}`
      );
      setMyRiskData(res.data);
      console.log(res.data, " full");
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getFullReport();
  }, []);

  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Difference in milliseconds
    const differenceInTime = end - start;

    // Convert time difference to days, months, and years
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const differenceInMonths = differenceInDays / 30.44; // Approximate months
    const differenceInYears = differenceInMonths / 12;

    return {
      days: Math.floor(differenceInDays),
      months: Math.floor(differenceInMonths),
      years: differenceInYears,
    };
  };

  const { months } = calculateDateDifference(
    myRiskdata?.startDate,
    myRiskdata?.endDate
  );
  let temp1 = myRiskdata?.adaptations ?? {},
    data = {};
  Object.keys(temp1).forEach((key) => {
    if (Object.keys(data).includes(temp1[key]?.Pillar)) {
      data = {
        ...data,
        [temp1[key]?.Pillar]: [...data?.[temp1[key]?.Pillar], temp1[key]],
      };
    } else {
      Object.assign(data, { [temp1[key]?.Pillar]: [temp1[key]] });
    }
  });
  console.log(myRiskdata, "dataa");
  return (
    <>
      <div className="w-100 gap-3 flex justify-end p-5 items-center">
        <button
          onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}
          class="bg-[#318CE7] hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <svg
            class="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
          <span>PDF</span>
        </button>
      </div>
      {loading ? (
        <h1 className="absolute top-[50%] left-[50%] translate-x-[-50%]">
          Loading...
        </h1>
      ) : (
        <>
          <div
            id="printablediv"
            ref={targetRef}
            className="w-[85rem]  mx-auto "
          >
            <form>
              {/* Grid */}
              <div className="flex justify-center items-center  border-gray-200 ">
                <div>
                  <img alt="Logo" className="w-24 mx-auto" src="/logo.png" />
                </div>
              </div>
              <div className="grid grid-cols-2  border-b border-gray-200  p-4 pr-5">
                <div>
                  <div className="grid space-y-3  "></div>
                </div>
                {/* Col */}
                <div>
                  <div className="grid space-y-3 mt-[3px]"></div>
                </div>
                {/* Col */}
              </div>
              {/* End Grid */}
              <div className="pt-2 px-4 sm:px-6 lg:px-8">
                {/* Grid */}

                {/* End Grid */}

                <div className="">
                  <h4 className="text-xl pb-2 text-center md:text-left font-semibold  text-gray-800 ">
                    Detailed Risk Report Analysis
                  </h4>
                </div>
                {/* Table */}
                <div className="grid  grid-cols-2 gap-8 border border-gray-200 p-4 rounded-lg ">
                  <div className="flex flex-col items-center min-h-60 ">
                    <h3 className="mb-3 font-semibold text-gray-800 text-center md:text-left  ">
                      Map
                    </h3>

                    {/* <MapWithMarker
            markerPosition={[
              myLocation?.lat || -0.3615164,
              myLocation?.lng || 35.3084548,
            ]}
          /> */}
                    {isLoaded && (
                      <MapForReport
                        lat={myRiskdata?.latitude}
                        lng={myRiskdata?.longitude}
                      />
                    )}
                  </div>
                  <div className="flex flex-col justify-between gap-8  items-center ">
                    <h3 className="mb-1  font-semibold text-gray-800 text-center md:text-left ">
                      Composite Risk Score
                    </h3>
                    {/* <Gauge level={ /> */}
                    <div>
                      <ProgressBar
                        isBig={true}
                        //@ts-ignore
                        progress={myRiskdata?.composite_total_risk}
                        width={300}
                      />
                      <div className="flex items-center justify-between w-full  relative">
                        {/* Left Label */}
                        <div className="font-bold text-xs text-black">L</div>

                        {/* Line with Divisions */}
                        <div className="relative flex-grow mx-2">
                          {/* Main Line */}
                          <div className="h-[1px] bg-black w-full relative">
                            {/* Division Marks */}
                            <div className="absolute h-[10px] w-[1px] bg-black top-[-4px] left-[25%]"></div>
                            <div className="absolute h-[10px] w-[1px] bg-black top-[-4px] left-[50%]"></div>
                            <div className="absolute h-[10px] w-[1px] bg-black top-[-4px] left-[75%]"></div>
                          </div>
                        </div>

                        {/* Right Label */}
                        <div className="font-bold text-xs text-black">H</div>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="">
                        <h4 className="text-xs font-semibold uppercase text-gray-800 text-center md:text-left ">
                          Summary
                        </h4>
                        <ul className="mt-3 flex flex-col bg-[#FAFAFA]">
                          <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg  ">
                            <div className="flex items-center justify-between w-full text-center md:text-left">
                              <span>Time (in months)</span>
                              <span>{months + 1}</span>
                            </div>
                          </li>
                          <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                            <div className="flex items-center justify-between w-full text-center md:text-left">
                              <span>Livestock</span>
                              <span>{myRiskdata?.livestock_type}</span>
                            </div>
                          </li>
                          <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                            <div className="flex items-center justify-between w-full text-center md:text-left">
                              <span>Biodiversity</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="green"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="lucide lucide-badge-check"
                              >
                                <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                                <path d="m9 12 2 2 4-4" />
                              </svg>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Table */}
                <div className="grid grid-cols-1 gap-6 mb-16 ">
                  <p className="text-center md:text-left font-semibold pt-3">
                    {" "}
                    Climate Risk
                  </p>
                  <div className="flex p-4 items-center py-6 bg-[#F9F9F9]">
                    <div className="w-100 md:w-[33%]">
                      <GaugePdf
                        level={
                          myRiskdata?.climate_scores?.composite_climate_risk
                        }
                      />
                    </div>
                    <div className="w-[33%]">
                      <ProgressBar
                        piller={climate_indices[0]}
                        width={"100%"}
                        progress={myRiskdata?.climate_scores.drought_risk}
                      />
                    </div>
                    <div className="w-[33%]">
                      <ProgressBar
                        width={"100%"}
                        piller={climate_indices[1]}
                        progress={
                          myRiskdata?.climate_scores
                            ?.temperature_humidity_index_risk
                        }
                      />
                    </div>
                  </div>
                  <p className="text-center md:text-left font-semibold pt-3">
                    Water Risk
                  </p>
                  <div className="flex p-4 py-6 items-center bg-[#F9F9F9]">
                    <div className="w-100 md:w-[33%]">
                      <GaugePdf
                        level={myRiskdata?.water_scores?.composite_water_risk}
                      />
                    </div>
                    <div className="w-[33%]">
                      <ProgressBar
                        piller={water_indices[0]}
                        width={"100%"}
                        progress={myRiskdata?.water_scores.water_stress_risk}
                      />
                    </div>
                    <div className="w-[33%]">
                      <ProgressBar
                        piller={water_indices[1]}
                        width={"100%"}
                        progress={myRiskdata?.water_scores?.ground_water_risk}
                      />
                    </div>
                  </div>
                  <p className="text-center md:text-left font-semibold pt-3">
                    Profitability
                  </p>
                  <div className="flex p-4 py-6 items-center  bg-[#F9F9F9]">
                    <div className="w-[33%]">
                      <GaugePdf
                        level={
                          myRiskdata?.profitability_scores?.profitability_risk
                        }
                      />
                    </div>
                    <div className="w-[33%]">
                      <ProgressBar
                        piller={profitability_indices[0]}
                        width={"100%"}
                        progress={
                          myRiskdata?.profitability_scores?.profitability_risk
                        }
                      />
                    </div>
                  </div>
                  {/* <TopGauge
                pillar="CLIMATE"
                rainfall_risk={myRiskdata?.climate_scores.drought_risk}
                //@ts-ignore
                temperature_risk={myRiskdata?.climate_scores.precipitation_risk}
                //@ts-ignore
                drought_risk={myRiskdata?.climate_scores.temperature_risk}
                composite_climate_risk={
                  myRiskdata?.climate_scores.composite_climate_risk
                }
                categories={climate_indices}
              /> */}
                  {/* <TopGauge
                pillar="WATER"
                rainfall_risk={myRiskdata?.water_scores.ground_water_risk}
                temperature_risk={
                  myRiskdata?.water_scores.rainfall_erosivity_risk
                }
                drought_risk={myRiskdata?.water_scores.location_aquaduct_risk}
                composite_climate_risk={
                  myRiskdata?.water_scores.composite_water_risk
                }
                categories={water_indices}
              /> */}
                  {/* <TopGauge
                pillar="SOIL"
                rainfall_risk={myRiskdata?.soil_scores.soil_organic_carbon_risk}
                temperature_risk={myRiskdata?.soil_scores.soil_ph_risk}
                drought_risk={
                  myRiskdata?.soil_scores.cation_exchange_capacity_risk
                }
                composite_climate_risk={
                  myRiskdata?.soil_scores.composite_soil_risk
                }
                categories={soil_indices}
              /> */}
                </div>
                <div className="mt-2 md:mt-5">
                  <h4 className="text-2xl mb-2 font-semibold text-gray-800 ">
                    Climate Adaptation Suggestions To Improve Your Score
                  </h4>
                  {Object.keys(data).map((key) => {
                    return (
                      <>
                        <h4 className="text-lg mb-2 font-semibold text-gray-800 ">
                          {key}
                        </h4>
                        {data[key].map(({ Suggestion }) => {
                          return (
                            <>
                              <p className="text-[#505050] text-sm font-normal mt-2">
                                {myRiskdata?.adaptations[1] && (
                                  <li>{Suggestion}</li>
                                )}
                              </p>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
                <div className="mt-8">
                  <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                    Thank you!
                  </h4>
                  <p className="text-gray-500">
                    If you have any questions concerning this report, use the
                    following contact information:
                  </p>
                  <div className="mt-2">
                    <p className="block text-sm font-medium text-gray-800 ">
                      info@adapta.earth
                    </p>
                  </div>
                </div>
                <div className="flex justify-between">
                  <p className="mt-5 text-sm text-gray-500">© 2023 ADAPTA.</p>
                  <p className="mt-5 text-[12px] text-gray-500 text-end">
                    Report Id : {reportId}
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="px-4 sm:px-6 lg:px-8 w-[85rem]  mx-auto">
            <div className=" flex gap-x-3 pt-2  ">
              <button
                class="w-[150px] mt-3 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm "
                onClick={() => navigate("/")}
              >
                Calculate More
              </button>
              <button
                class=" w-[150px] mt-3 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm "
                onClick={() => navigate("/")}
              >
                Exit
              </button>
              <div className="flex gap-x-2 w-100  md:justify-center">
                <button
                  onClick={() => navigate("/survey")}
                  className="cursor-pointer text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-[150px] mt-3 py-3 px-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Click here
                </button>{" "}
                <h1 className="text-center   text-md mt-5 font-semibold align-middle mb-3">
                  and help us make Adapta climate score better.
                </h1>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SuccessLivestock;
