import biodiversity from "../../assets/images/biodiversity.jpg";
import climate from "../../assets/images/climate.jpg";
import water from "../../assets/images/water.jpg";
import soil from "../../assets/images/soil.jpg";
import profitablity from "../../assets/images/Profitability.jpg";
import PillerCard from "../PillerCard";

const pillerData = [
  {
    image: biodiversity,
    title: "Biodiversity Check",
    hoverText:
      "ADAPTA CS maps protected areas such as national parks to help you avoid farming or investing in these vital ecological zones.",
    hoverHeading: "Biodiversity Check",
  },
  {
    image: climate,
    title: "Climate Risk",
    hoverText:
      "We forecast climate change over time, providing a risk score to guide your decisions. ",
    hoverHeading: "Climate Risk",
  },
  {
    image: soil,
    title: "Soil Risk",
    hoverText:
      "Soils store 75% of the carbon pool    on land. Their health is crucial for both agriculture and combating climate change.",
    hoverHeading: "Soil Risk",
  },
  {
    image: water,
    title: "Water Risk",
    hoverText:
      "With global water mismanagement, understanding water risks and mitigation strategies is essential for sustainable agriculture.",
    hoverHeading: "Water Risk",
  },
  {
    image: profitablity,
    title: "Profitability",
    hoverText:
      "Farm management is a key component in the sustainability of livestock operations.",
    hoverHeading: "Profitability",
  },
];

const Piller = () => {
  return (
    <div className="text-center  my-[25px] md:my-[100px]">
      <h2 className="text-[24px] md:text-[50px] font-black">
        Climate Score Pillars
      </h2>
      <p className="text-[16px] text-[#4E4E4E]">
        We have developed a climate score that integrates five key elements of
        agricultural production systems
      </p>
      <button
        type="button"
        className="text-white mt-6 bg-[#338FD9] border focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-[55px] py-[19px] text-center leading-4 mb-5"
      >
        Buy Report
      </button>
      <div className="grid   xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mx-3">
        {pillerData.map(({ image, hoverHeading, hoverText, title }) => (
          <PillerCard
            title={title}
            image={image}
            hoverHeading={hoverHeading}
            hoverText={hoverText}
          />
        ))}
      </div>
    </div>
  );
};

export default Piller;
